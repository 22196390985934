<template>
  <!-- Product Form start -->
  <div class="bee-content-block">
    <div class="container">
      <div class="row flex-lg-row-reverse">
        <div class="col-12 col-lg-9">
          <div class="my-account-page-title">
            <h3>Create Product</h3>
          </div>
          <div class="order-form">
            <form action="#">
              <div class="form-group">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>Product name:</label>
                    <input v-model="product.title" type="text" class="form-control" name="name" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>Price:</label>
                    <input v-model="product.price" type="text" class="form-control" name="price" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>Product category:</label>
                    <input
                      v-model="product.category"
                      type="text"
                      class="form-control"
                      name="name"
                    />
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>Image:</label>
                    <input v-model="product.image" type="text" class="form-control" name="price" />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-12">
                    <label>Description:</label>
                    <textarea
                      v-model="product.description"
                      name="description"
                      class="form-control form-text"
                    ></textarea>
                  </div>
                </div>
              </div>

              <button type="button" class="btn btn-submit" @click="saveData">Create Product</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Product Form End -->
</template>

<script>
import { db } from "@/includes/firebase";

export default {
  name: "ProductForm",
  data() {
    return {
      products: [],
      product: {
        id: 12,
        title: "WD 4TB Gaming Drive Works with Playstation 4 Portable External Hard Drive",
        price: 114,
        description:
          "Expand your PS4 gaming experience, Play anywhere Fast and easy, setup Sleek design with high capacity, 3-year manufacturer's limited warranty",
        category: "electronics",
        image: "https://fakestoreapi.com/img/61mtL65D4cL._AC_SX679_.jpg",
        rating: {
          rate: 4.8,
          count: 400,
        },
      },
    };
  },
  methods: {
    readData() {
      db.collection("products")
        .get()
        .then((querySnapshot) => {
          // this.products = querySnapshot;
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            this.products.push(doc.data());
          });
        });
    },
    saveData() {
      db.collection("products")
        .add(this.product)
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          this.readData();
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    reset() {},
  },
  created() {
    this.readData();
  },
};
</script>

<style></style>
