<template>
  <!-- Breadcroumbs start -->
  <Breadcrumbs title="Product" />
  <!-- Breadcroumbs end -->
  <ProductForm />
  <Newsletter />
</template>

<script>
// @ is an alias to /src

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import ProductForm from "@/components/ProductForm.vue";
import Newsletter from "@/components/Newsletter.vue";

export default {
  name: "Products",
  components: {
    Breadcrumbs,
    ProductForm,
    Newsletter,
  },
};
</script>
